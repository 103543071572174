import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./toolkitReducers";
import { cryptouchAPI } from "./toolkitReducers/cryptouch";
import { ipDataApi } from "./toolkitReducers/ipDataApi";
import { binanceApi } from "./toolkitReducers/binanceApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { themeMiddleware } from "./toolkitReducers/themeSlice";

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(cryptouchAPI.middleware)
        .concat(ipDataApi.middleware)
        .concat(binanceApi.middleware)
        .concat(themeMiddleware),
  });

setupListeners(store.dispatch)