import { useTranslation } from "react-i18next";
import { CustomSelect } from "../Binary/CustomSelect";
import { useState } from "react";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
import { useGetUserSessionsQuery } from "../../toolkitReducers/cryptouch";
import { selectTheme } from "../../toolkitReducers/selectors";
import { useSelector } from "react-redux";
export const SessionsTable = () => {
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState(5);
  const { data } = useGetUserSessionsQuery();
  const theme = useSelector(selectTheme);
  const handleDisplayPerPage = (count = null) => {
    setPerPage(count);
  };

  return (
    <section
      className={
        theme === "light"
          ? "sessions-section medium-wrapper"
          : "sessions-section medium-wrapper dark-profile"
      }
    >
      <div className="sessions-section__header">
        <h2 className="sessions-section__header-heading">
          {t("История сеансов")}
        </h2>
        <CustomSelect
          handleDisplayPerPage={handleDisplayPerPage}
          perPage={perPage}
        />
      </div>

      <div className="sessions-section__table-wrapper">
        <table className="sessions-section__table-body">
          <thead>
            <tr className="sessions-section__table-heading-row rel">
              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>{t("Дата")}</span>
                </div>
              </th>
              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>{t("IP ADDRESS")}</span>
                </div>
              </th>
              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>OS</span>
                </div>
              </th>

              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>{t("Браузер")}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              [...data]
                .reverse()
                .splice(0, perPage || data.length)
                .map((session, index) => (
                  <tr key={index} className="sessions-section__table-body-row">
                    <td>
                      {transformDateTimeForSessionsTable(
                        session.last_login_date
                      )}
                    </td>
                    <td>{session.last_ip_address}</td>
                    <td>{session.last_os}</td>
                    <td>{session.last_browser}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
