import { BalanceList } from "../Referals/BalanceList";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import ProfilePicture from "../../assets/images/profile.svg";
import ProfilePictureDark from "../../assets/images/profile-dark.svg";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
export const UserInfo = () => {
  const { data } = useGetUserDataQuery();
  const theme = useSelector(selectTheme);

  return (
    <div className="profile-section__client-info-wrapper">
      <div className="profile-section__client-photo-wrapper rel">
        <img
          src={theme === "light" ? ProfilePicture : ProfilePictureDark}
          className="profile-section__client-photo"
          alt="profile"
        />
      </div>

      <div className="profile-section__client-info">
        <h2 className="profile-section__client-name h2">
          {`${data && data.username} ${data && data.last_name}`}
        </h2>
        <BalanceList balance={data} type="main" />
      </div>
    </div>
  );
};
