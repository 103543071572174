
import Header from "../Elements/Header";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Aside from "../Elements/Aside";
// import Footer from "../Elements/Footer";
import { Footer } from "./MainPage/Footer";
import { useTranslation } from "react-i18next";
import { getToken } from "../helpers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectTheme } from "../toolkitReducers/selectors";


import {
  closeTooltip,
  openLiq,
  closeLiq,
} from "../toolkitReducers";
import { Popup } from "../Elements/Popup";
import Liquidated from "./Algoritms/DepositSuccess";
export default function Main() {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme)

    const { liq } = useSelector((state) => state.auth)
  const { t } = useTranslation();
  const { fetching } = useSelector((state) => state.state);
  const navigate = useNavigate();
  const location = useLocation();


  const switchDone = (bool) => {

    dispatch(closeLiq());
  };
  useEffect(() => {
    if (liq === true) {
      dispatch(openLiq());
    }
  }, [dispatch, liq]);



useEffect(() => {
    if (location.pathname === "/" && getToken() !== null) {
      navigate("/profile");
    }
    if (getToken() === null) {
      navigate("/main");
    }
  }, [location.pathname, navigate]);

  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.code === "Escape") {

      dispatch(closeLiq())
    }
  };
  return (
    <div className={theme === "light" ? "page" : "page dark-profile"}>

      {fetching === "loading" && <Spinner />}
      <Header />
      {/* <Tooltip /> */}
      <Popup
        props={{
          expanded: liq,
          onModalClose: closeModal,
        }}
        children={
          <Liquidated
            title={t("Your trade pull has been liquidated")}
            type="failure"
            switchDone={switchDone}
            description="Manage your risks next time"
          />
        }
      />
      {/* <Liquidated /> */}
      <div className="page__body-wrapper">
        <Aside />

        <div className="page__sections-wrapper medium-wrapper">
          <Outlet />
        </div>
      </div>
      <div className="main-page">
        <Footer onProfilePage={location.pathname !== "/"}/>
      </div>
    </div>
  );
}

export const Tooltip = (props) => {
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.state.tooltipRedirect);
  const text = useSelector((state) => state.state.tooltipText);
  const { t } = useTranslation();

  const { tooltip } = useSelector(({ state }) => state);

  const clickDone = () => {
    // navigate('/profile')

    console.log("redirect :>> ", redirect);
    dispatch(closeTooltip());
  };

  return (
    <>
      {tooltip && (
        <div className="tooltip">
          <div>{text ? text : t("Данные были успешно обновленны")}</div>

          <button
            className="KYS-section__next-page btn btn--primary"
            onClick={clickDone}
            type="button"
          >
            {t("Готово")}
          </button>
        </div>
      )}
    </>
  );
};


const Spinner = () => {
  return (
    <div className="site-spinner">
      <span className="loader"></span>
    </div>
  );
};
