import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { AlgorithmPromoCard } from "./AlgorithmPromoCard";
import { listOfAlgorithms, algorithmsDescriptions } from "../../helpers/lists";

const Algoritms = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <section ref={ref} className="main-page-section algoritms-section">
      <div className="section-inner-container">
        <h3 className="animated-bg">{t("Возможности с CrypTouch AI")}</h3>
        <div className="cards-holder">
          {listOfAlgorithms.map(({ type, features, launchSum }) => (
            <AlgorithmPromoCard
              type={type}
              features={features}
              launchSum={launchSum}
            />
          ))}
        </div>
        <div className="lower-part">
          {
            <ul>
              {algorithmsDescriptions.map((description, idx) => (
                <li>
                  <p>{t(description)}</p>
                </li>
              ))}
            </ul>
          }
        </div>
      </div>
    </section>
  );
});

export { Algoritms };
