import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PurpleCircle } from "../../assets/icons/promo-section/purple-circle.svg";
import { useTranslation } from "react-i18next";
import { partnershipList } from "../../helpers/lists";
export const PartnershipNew = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  return (
    <section ref={ref} className="main-page-section partnership-new-section">
      <div className="section-inner-container">
        <div className="face-bg-wrapper" data-animated='fade-in-and-scale'></div>
        <div className="content-wrapper">
          <h2>{t("Партнерская программа")}</h2>
          <p className="description">
            {t(
              "Станьте частью нашей партнерской программы совершенно бесплатно и начните зарабатывать уже сегодня."
            )}
          </p>
          <ul className="partnership-list" data-animated='ltr-slide-in'>
            {partnershipList.map((e) => (
              <li key={e}>
                <PurpleCircle />
                <p>{t(e)}</p>
              </li>
            ))}
          </ul>

          <div className="link-wrapper">
            <Link to="/register" className="link-button">
              {t("Зарегистрироваться прямо сейчас")}
            </Link>
            <a
              className=""
              rel="noreferrer"
              target="_blank"
              href={
                i18n.resolvedLanguage === "en"
                  ? "https://www.youtube.com/watch?v=SHS4pLtPbcw"
                  : "https://www.youtube.com/watch?v=_cmWSqBhzFM"
              }
            >
              {t("Видео регистрации")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});
