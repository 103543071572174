import { CurrencyCard } from "./CurrencyCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCryptoPrices } from "../../toolkitReducers/actions.slice";
import {
  selectCoinPriceBTC,
  selectCoinPriceETH,
} from "../../toolkitReducers/selectors";
export const CurrencyRatesSection = () => {
  const dispatch = useDispatch();
  const coinPriceETH = useSelector(selectCoinPriceETH);
  const coinPriceBTC = useSelector(selectCoinPriceBTC);
  useEffect(() => {
    dispatch(getCryptoPrices());
  }, [dispatch, coinPriceETH?.price, coinPriceBTC?.price]);

  return (
    <section className="main-page-section currency-rates-section">
      <div className="section-inner-container">
        <div className="hero-coin-cards">
          <CurrencyCard
            currency="BTC"
            course={coinPriceBTC?.percent_change_24h}
            price={coinPriceBTC?.price}
          />
          <CurrencyCard
            currency="ETH"
            course={coinPriceETH?.percent_change_24h}
            price={coinPriceETH?.price}
          />
          <CurrencyCard
            currency="BTC"
            course={coinPriceBTC?.percent_change_24h}
            price={coinPriceBTC?.price}
          />
          <CurrencyCard
            currency="ETH"
            course={coinPriceETH?.percent_change_24h}
            price={coinPriceETH?.price}
          />
        </div>
      </div>
    </section>
  );
};
