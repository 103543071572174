import { Withdraw } from "../../Elements/Modals/Withdraw";
import { Popup } from "../../Elements/Popup";
import DepositSuccess from "../Algoritms/DepositSuccess";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { useTranslation } from "react-i18next";
export const ProfileControls = () => {
  const [isDone, setDone] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const { data } = useGetUserDataQuery();
  const { t } = useTranslation();
  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.code === "Escape") {
      setWithdrawModalOpen(false);
    }
  };
  const switchDone = (bool) => {
    setDone(bool);
  };

  const closePopup = () => {
    switchDone(false);
    setWithdrawModalOpen(false);
  };
  return (
    <>
      <div className="profile-section__client-contacts">
        <div className="profile-section__client-contacts-item profile-section__client-contacts-item--login">
          <p className="profile-section__client-contacts-item-title">
            {t("Логин")}:
          </p>
          <p className="profile-section__client-contacts-item-info">
            {data && data.username}
          </p>
          <Link
            to="/new-password"
            className="profile-section__client-contacts-item-link link"
          >
            {t("Сменить пароль")}
          </Link>
        </div>
        <div className="profile-section__client-contacts-item profile-section__client-contacts-item--email">
          <p className="profile-section__client-contacts-item-title">E-mail:</p>
          <p className="profile-section__client-contacts-item-info">
            {data && data.email}
          </p>
        </div>
        <div className="profile-section__client-contacts-item profile-section__client-contacts-item--get-money">
          <p className="profile-section__client-contacts-item-title">
            {t("Вывод средств")}:
          </p>
          <Link
            onClick={() => setWithdrawModalOpen(true)}
            className="profile-section__client-contacts-item-link link"
          >
            {t("Заполнить заявку")}
          </Link>
        </div>
      </div>
      <Popup
        props={{
          expanded: withdrawModalOpen,
          onModalClose: closeModal,
        }}
        children={
          isDone ? (
            <DepositSuccess
              title={t("Поздравляем!")}
              description={t("Трансфер успешен")}
              switchDone={closePopup}
            />
          ) : (
            <Withdraw switchDone={switchDone} />
          )
        }
      />
    </>
  );
};
