import { ReactComponent as LogoShortened } from "../../assets/icons/main-page/logo-shortened.svg";
import { ReactComponent as LogoSecond } from "../../assets/icons/main-page/logo-second.svg";
import { useTranslation } from "react-i18next";
export const CrossStripesBranding = () => {
  const { t } = useTranslation();

  return (
    <div className="cross-stripes-branding">
      <ul className="purple-stripe">
        {Array(10)
          .fill("")
          .map((e, idx) => (
            <li key={"purple-stripe-item" + idx}>
              <p>{t("Почему мы предлагаем делиться прибылью с вами?")}</p>
              <LogoShortened />
            </li>
          ))}
      </ul>
      <ul className="white-stripe">
        {Array(20)
          .fill("")
          .map((e, idx) => (
            <li key={"purple-stripe-item" + idx}>
              <LogoSecond />
            </li>
          ))}
      </ul>
    </div>
  );
};
