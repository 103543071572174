import { useRef, useEffect } from "react";

import {
  Algoritms,
  Advantages,
  // Development,
  // Features,
  Feedback,
  Footer,
  Header,
  // Hero,
  // Partnership,
  PartnershipNew,
  // Promo,
  VideoBlock,
  // Events,
  News,
  DevelopmentMap,
  HeroNew,
  CurrencyRatesSection,
  PromoNew,
  FeaturesNew
  
} from "./";
import { getToken } from "../../helpers";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";

const MainPage = () => {
  const theme = useSelector(selectTheme);
  const refHero = useRef(null);
  const refPromo = useRef(null);
  const refFeatures = useRef(null);
  const refAdvantages = useRef(null);
  const refAlgoritms = useRef(null);
  const refVideo = useRef(null);
  const refFeedback = useRef(null);
  const refDevelopment = useRef(null);
  const refPartnership = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/main" && getToken() !== null) {
      navigate("/profile");
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    refHero.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  useEffect(() => {
    const listItems = document.querySelectorAll('[data-animated=ltr-slide-in]')
    const blockItems = document.querySelectorAll('[data-animated=fade-in-and-scale]')
    const listItemsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) entry.target.classList.add("slide-from-left")
          if (!entry.isIntersecting) entry.target.classList.remove("slide-from-left")
      })
    })
    const blockItemsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) entry.target.classList.add("fade-in-and-scale")
          if (!entry.isIntersecting) entry.target.classList.remove("fade-in-and-scale")
      })
    })
    listItems.forEach((item) => listItemsObserver.observe(item))
    blockItems.forEach((item) => blockItemsObserver.observe(item))

    return ()=> {
      listItemsObserver.disconnect()
      blockItemsObserver.disconnect()
    }
  })

  const scrollToElement = (elem) => {
    if (elem === "hero") {
      refHero.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "promo") {
      refPromo.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "features") {
      refFeatures.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "advantages") {
      refAdvantages.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "algoritms") {
      refAlgoritms.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "video") {
      refVideo.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "feedback") {
      refFeedback.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "development") {
      refDevelopment.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (elem === "partnership") {
      refPartnership.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  
  return (
    <div className={theme === "light" ? "main-page" : "main-page dark"}>
      <Header scrollToElement={scrollToElement} />
      {/* <Hero ref={refHero} /> */}
      <HeroNew ref={refHero}/>
      <CurrencyRatesSection/>
      <PromoNew ref={refPromo}/>
      {/* <Promo ref={refPromo} refVideo={refVideo} /> */}
      {/* <Features ref={refFeatures} /> */}

      <div className="wave-image-holder">
        <div className="waves-background"></div>
        <FeaturesNew ref={refFeatures} />
        <Advantages ref={refAdvantages} refPartnership={refPartnership} />
        <Algoritms ref={refAlgoritms} />
      </div>
      {/* <Events /> */}
      <PartnershipNew ref={refPartnership} />
      <VideoBlock ref={refVideo} />
      <News/>

      <Feedback ref={refFeedback} />
      {/* <Development ref={refDevelopment} /> */}
      <DevelopmentMap ref={refDevelopment}/>
      <Footer scrollToElement={scrollToElement} />
    </div>
  );
};

export { MainPage };
