import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as X } from "../../assets/icons/twitter.svg";
import { ReactComponent as Telegram } from "../../assets/icons/refferal-form/telegram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/refferal-form/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/refferal-form/instagram.svg";
import { ReactComponent as CopyEmail } from "../../assets/icons/refferal-form/copy-email.svg";
export const ReferalForm = forwardRef(({ id }, ref) => {
  const { t } = useTranslation();
  return (
    <div className="balance-page_referal-form">
      <p className="profile-section__heading">{t("Реферальный ID")}</p>

      <div className="deposit-window__currency-address-wrapper rel">
        <label
          className="copy-email-label"
          aria-roledescription="button"
          htmlFor="register-link"
          onClick={() => navigator.clipboard.writeText(ref.current.value)}
        >
<CopyEmail/>
        </label>
        <input
          className="copy-email"
          type="text"
          name="register-link"
          id="register-link"
          value={`https://cryptouch.ai/register?ref_id=${
            id?.toString().padStart(5, "1000") || "0"
          }`}
          readOnly
          ref={ref}
        />
      </div>

      <div className="profile-section__social-links-wrapper">
        <p className="profile-section__social-links-title">{t("Поделится")}:</p>

        <ul className="profile-section__social-links-list">
          <a target="blank" href="https://www.instagram.com/cryptouch_ai/">
            <li className="profile-section__social-links-list-item link">
              <Instagram />
            </li>
          </a>

          <a
            target="blank"
            href="https://www.facebook.com/profile.php?id=61553277059433"
          >
            <li className="profile-section__social-links-list-item link">
              <Facebook />
            </li>
          </a>

          <a target="blank" href="https://t.me/ct_support_office">
            <li className="profile-section__social-links-list-item link">
              <Telegram />
            </li>
          </a>

          <a target="blank" href="https://twitter.com/cryptouch_ai">
            <li className="profile-section__social-links-list-item link">
              <X />
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
});
