const currencies = ["btc", "eth", "usdt"];

const balanceCardsList = [
  {
    currency: "btc",
    financialOperationType: "Депозит"
  },
  {
    currency: "eth",
    financialOperationType: "Снятие"
  },
  {
    currency: "usdt",
    financialOperationType: "Трансфер"
  },
]
// languages for i18n
const lngs = {
  en: { nativeName: "English", shortName: "EN", originName: "English" },
  ru: { nativeName: "Russian", shortName: "RU", originName: "Русский" },
  vi: { nativeName: "Vietnamese", shortName: "VI", originName: "tiếng Việt" },
  es: { nativeName: "Spanish", shortName: "ES", originName: "Español" },
};

const refferalsLargeTableRows = [
  "№",
  "Почта партнера",
  "Дата регистрации",
  "Дата деп",
  "Баланс BTC",
  "Баланс ETH",
  "Баланс USDT",
];
// list to render development map on main page
const developmentMap = [
  {
    period: "Q4 2024",
    goals: [
      {
        goalTitle: "Демонстративный плагин торговли AI CrypTouch",
        goalDetails: ["В пари BTC/USDT"],
      },
      {
        goalTitle: "Новый торговый алгоритм для рынка Forex",
        goalDetails: [
          "Разработка и запуск алгоритма, ориентированного на торговлю валютами на рынке Forex.",
        ],
      },
      {
        goalTitle: "Обновление аффилейт программы",
        goalDetails: [
          "Введение новых карьерных уровней для участников аффилейт программы.",
          "Добавление трёх новых видов бонусов для партнёров.",
        ],
      },
      {
        goalTitle: "Обновление платформы",
        goalDetails: [
          "Оптимизация скорости и производительности для улучшения пользовательского опыта.",
        ],
      },
    ],
  },
  {
    period: "Q1 2025",
    goals: [
      {
        goalTitle: "Запуск собственного токена CrypTouch AI (CTAI)",
        goalDetails: [
          "Разработка и интеграция токенов на платформе",
          "Закрытая продажа токенов для партнёров и лидеров компании.",
        ],
      },
      {
        goalTitle: "Редизайн платформы",
        goalDetails: [
          "Обновление дизайна для повышения удобства и визуальной привлекательности.",
        ],
      },
      {
        goalTitle: "Публичная продажа токенов CrypTouch AI (CTAI).",
        goalDetails: [
          "Открытая продажа токенов для всех пользователей платформы.",
        ],
      },
      {
        goalTitle: "Внедрение и тест транзакций CrypToch AI токен (CTAI).",
        goalDetails: [],
      },
    ],
  },
  {
    period: "Q2 2025",
    goals: [
      {
        goalTitle: "Разработка мобильного приложения",
        goalDetails: [
          "Запуск мобильных приложений для Android и iOS для упрощения доступа к платформе.",
        ],
      },
      {
        goalTitle: "Интеграция с банковскими сервисами",
        goalDetails: [
          "Внедрение фиатных шлюзов для удобного пополнения и вывода средств.",
        ],
      },
      {
        goalTitle: "Расширение линейки алгоритмов",
        goalDetails: [
          "Разработка новых AI-алгоритмов для торговли различными криптовалютами, включая DeFi и NFT-токены.",
        ],
      },
      {
        goalTitle: "Масштабирование инфраструктуры",
        goalDetails: [
          "Увеличение блокчейн мощностей и внедрение облачных решений для поддержки роста платформы.",
        ],
      },
    ],
  },
  {
    period: "Q3 2025",
    goals: [
      {
        goalTitle: "Внедрение интерактивных AI-помощников для пользователей",
        goalDetails: [
          "Разработка AI-советников, которые помогут пользователям оптимизировать свои инвестиционные стратегии, основываясь на анализе рынка.",
          "Выход на ICO",
          "Листинг CTAI на других платформах.",
        ],
      },
    ],
  },
  {
    period: "Q4 2025",
    goals: [
      {
        goalTitle: "Запуск образовательной платформы",
        goalDetails: [
          "Создание обучающих курсов по алгоритмической торговле и использованию искусственного интеллекта для повышения квалификации пользователей.",
        ],
      },
      {
        goalTitle: "Интеграция с новыми торговыми площадками",
        goalDetails: [
          "Подключение к новым крипто биржам и рынкам, расширение ассортимента торгуемых активов на платформе.",
        ],
      },
      {
        goalTitle: "Запуск собственных инвестиционных фондов",
        goalDetails: [
          "Создание инвестиционных фондов под управлением AI для привлечения институциональных инвесторов.",
        ],
      },
    ],
  },
  {
    period: "Q1-Q4 2026",
    goals: [
      {
        goalTitle: "Запуск собственной криптобиржи CrypTouch Exchange",
        goalDetails: [
          "Старт работы криптобиржи, предоставляющей пользователям возможность торговать широким спектром активов, включая криптовалюты, токены и традиционные финансовые инструменты.",
        ],
      },
      {
        goalTitle: "Введение маржинальной торговли",
        goalDetails: [
          "Возможность для пользователей торговать с использованием кредитного плеча.",
        ],
      },
      {
        goalTitle: "Интеграция с DeFi",
        goalDetails: [
          "Подключение платформы к децентрализованным финансовым сервисам для расширения возможностей пользователей.",
        ],
      },
    ],
  },
];

const developmentList = [
  {
    date: "Четвертый квартал 2024 года",
    shortName: "Q4 2024/",
  },
  {
    date: "Первый квартал 2025 года",
    shortName: "Q1 2025/",
  },
  {
    date: "Второй квартал 2025 года",
    shortName: "Q2 2025/",
  },
  {
    date: "Третий квартал 2025 года",
    shortName: "Q3 2025/",
  },
  {
    date: "Четвертый квартал 2025 года",
    shortName: "Q4 2025/",
  },
  {
    date: "С первого по четвертый квартал 2026 года",
    shortName: "Q1-Q4 2026/",
  },
];
const listOfAlgorithms = [
  {
    type: "Торговый Алгоритм",
    features: [
      "доходность в месяц До 7%",
      "доступные депозиты от 10$",
      "вывод дивидендов каждую неделю",
      "доступ к обучению",
      "доступ к участию в партнерской программе",
      "вывод тела каждые шесть месяцев",
    ],
    launchSum: 10,
  },
  {
    type: "Арбитражный Алгоритм",
    features: [
      "доступные депозиты от 50$ до 100000$",
      "доходность до 10%",
      "вывод дивидендов каждую неделю",
      "доступ к обучению",
      "доступ к участию в партнерской программе",
      "вывод тела каждые 9 месяцев",
    ],
    launchSum: 50,
  },
  {
    type: "Комбо Алгоритм",
    features: [
      "объедините два алгоритма в один", 
      "доступные депозиты от 100$ до 100 000$",
      "доходность от 18% до 20%",
      "вывод дивидендов каждую неделю",
      "доступ к обучению",
      "доступ к участию в партнерской программе",
    ],
    launchSum: 100,
  },
];

const featuresList= [
  {
    name: "Рост",
    description: "Совместное развитие"
  },
  {
    name: "Возможности",
    description: "Открытие перспектив"
  },
  {
    name: "Заработок",
    description: "Выгодное партнерство"
  },
]

const algorithmsDescriptions = [
  "При выборе торговых алгоритмов они работают на одной бирже",
  "При выборе арбитражных алгоритмов, они работают на нескольких биржах",
  "Комбо алгоритмы запускаются на торговом или арбитражном алгоритме в зависимости от рыночной ситуации.",
  "Вывода тела депозита по запросу или заполнению заявки"
]

const partnershipList = [
  "Начните зарабатывать от 5%, активировав аллгоритмы от 50 usdt",
  "Зарабатывайте от 100$ в месяц, привлекая новых клиентов.",
  "Получайте ваш заработок еженедельно."
]
export {
  currencies,
  lngs,
  refferalsLargeTableRows,
  developmentMap,
  developmentList,
  listOfAlgorithms,
  featuresList,
  algorithmsDescriptions,
  partnershipList,
  balanceCardsList
};
