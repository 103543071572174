
import { useTranslation } from "react-i18next";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { BalanceList } from "./BalanceList";

const UserInfo = ({ balance }) => {

const {data} = useGetUserDataQuery()

  const { t } = useTranslation();
  return (
    <div className="referals-section-info-wrapper">
      <div className="info-wrapper-upper-part">
        <h2>{`${data && data.username} ${data && data.last_name}`}</h2>
        <div className="gradient-wrapper">
          <h3>{t("Бонусный баланс")}:</h3>
        </div>
      </div>
      <BalanceList balance={balance}/>
    </div>
  );
};

export { UserInfo };
