
import { useTranslation } from "react-i18next";
import { CurrencyPreview } from "./CurrencyPreview";

export const BalanceCard = ({
  coinName,
  currentBalance,
  financialOperationType,
  handleClick,
}) => {
    const {t} = useTranslation()

  return (
    <div className="balance-card">
      <div className="coin-name-wrapper">
        <CurrencyPreview currency={coinName}/>
      <p className="coin-name">{coinName}</p>
      </div>

      <p className="coin-price">{currentBalance}</p>
      <button
        className="withdrawal-button profile-section__widthdrawal-btn"
        onClick={() => handleClick(true)}
      >
        {t(financialOperationType)}
      </button>
    </div>
  );
};
