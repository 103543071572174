const selectRisks = (state) => state.state.risks;

const selectIsLoading = (state) => state.state.fetching;

const selectCurrentRisk = (state) =>
  state.state.risks?.[state.state.risks.length - 1]?.current_risk;

const selectError = (state) => state.state.error;
const selectAuthError = (state) => state?.auth?.error;
const selectUserMainBTCBalance = (state) =>
  state.auth?.user?.user?.main_crypto_balance_btc;

const selectUserMainUSDTBalance = (state) =>
  state.auth?.user?.user?.main_crypto_balance_usdt;

const selectUserMainETHBalance = (state) =>
  state.auth?.user?.user?.main_crypto_balance_eth;

const selectUserName = (state) => state.auth?.user?.user?.username;
const selectUserSurame = (state) => state.auth?.user?.user?.last_name;

const selectRefferalCurrentLvl = (state) =>
  state.state.referrals?.current_referral_level;

const selectBonusCryptoBalance = (state) =>
  state.state.referrals?.bonus_crypto_balance;

const selectRefferals = (state) => state.state.referrals;

const selectIsUserTrading = (state) => state.auth?.user?.user?.is_trading_user;

// selectors for transfer page (balances)
const selectMainBTC = (state) =>
  state.auth?.user?.user?.main_crypto_balance_btc;

const selectMainETH = (state) =>
  state.auth?.user?.user?.main_crypto_balance_eth;

const selectMainUSDT = (state) =>
  state.auth?.user?.user?.main_crypto_balance_usdt;

const selectBonusBTC = (state) =>
  state.auth?.user?.user?.bonus_crypto_balance_btc;

const selectBonusETH = (state) =>
  state.auth?.user?.user?.bonus_crypto_balance_eth;

const selectBonusUSDT = (state) =>
  state.auth?.user?.user?.bonus_crypto_balance_usdt;

const selectTradingBTC = (state) =>
  state.auth?.user?.user?.trading_crypto_balance_btc;

const selectTradingETH = (state) =>
  state.auth?.user?.user?.trading_crypto_balance_eth;

const selectTradingUSDT = (state) =>
  state.auth?.user?.user?.trading_crypto_balance_usdt;

const selectCoinPriceBTC = (state) => state.state.cryptoPrices?.BTC;

const selectCoinPriceETH = (state) => state.state.cryptoPrices?.ETH;

const selectTheme = (state) => state.theme.theme;

const selectLanguage = (state) => state?.language?.language;

const selectDailyProfit = state => state.state?.globalProfit?.daily_profit_data;

const selectCurrencies = (state) => state.state.currencies;
const selectBetHistory = (state) => state.state.betHistory;

// current_referral_level
// bonus_crypto_balance
export {
  selectRisks,
  selectCurrentRisk,
  selectError,
  selectUserMainBTCBalance,
  selectUserMainUSDTBalance,
  selectUserName,
  selectUserMainETHBalance,
  selectRefferalCurrentLvl,
  selectBonusCryptoBalance,
  selectRefferals,
  selectUserSurame,
  selectMainBTC,
  selectMainETH,
  selectMainUSDT,
  selectBonusBTC,
  selectBonusETH,
  selectBonusUSDT,
  selectTradingBTC,
  selectTradingETH,
  selectTradingUSDT,
  selectIsUserTrading,
  selectIsLoading,
  selectCoinPriceBTC,
  selectCoinPriceETH,
  selectTheme,
  selectLanguage,
  selectDailyProfit,
  selectCurrencies, selectBetHistory,
  selectAuthError
};
