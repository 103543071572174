import { useTranslation } from "react-i18next";

export const NetworkSelectBlock = ({
  selectedCoin,
  handleNetworkChange,
  selectedNetwork,
}) => {
  const { t } = useTranslation();

  return (
    <div className="network-select-block">
      <p className="deposit-window-label">{t("Сеть")}</p>
      <div className="network-select-wrapper">
        {selectedCoin === "usdt" && (
          <button
            onClick={() => handleNetworkChange?.("trc-20")}
            className={selectedNetwork === "trc-20" ? "selected" : ""}
          >
            {"Tron (TRX) TRC 20"}
          </button>
        )}
        {selectedCoin === "usdt" && (
          <button
            onClick={() => handleNetworkChange?.("bep-20")}
            className={selectedNetwork === "bep-20" ? "selected" : ""}
          >
            {"BEP-20"}
          </button>
        )}
      </div>
      <div>
        <p>{t("Создание заявок на вывод каждый четверг")}</p>
        <p>{t("Регламент до 48 часов")}</p>
      </div>
    </div>
  );
};
