import { AuthLogo } from "./AuthLogo";
import { ReactComponent as IconEyes } from "../../assets/icons/PassIcon.svg";
import { useState } from "react";
import { resetPassword } from "../../toolkitReducers";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "../../Elements/Popup";
import DepositSuccess from "../Algoritms/DepositSuccess";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectTheme } from "../../toolkitReducers/selectors";
export default function SetNewPass(params) {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmedPass, setShowConfirmedPass] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const theme = useSelector(selectTheme);
    const { t } = useTranslation();
  function handleSumbit(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Пароли не совпадают')
      return;
    }
    if (password.length < 6) {
      setError("Пароль должен состоять минимум из 6 символов");
      return;
    }
    setError(null)
    dispatch(resetPassword({ password, confirmPassword }));
    setIsOpen(true);
  }
  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.target.keyCode === "ESC") {
      setIsOpen(false);
    }
    if (e.target.hasAttribute("data-close-popup")) {
      setIsOpen(false);
      navigate("/profile");
    }
  };
  let modalProps = {
    onModalClose: closeModal,
    expanded: isOpen,
  };
  return (
    <div
      className={
        theme === "light"
          ? "restore-pass-page restore-pass-page--new-pass page"
          : "restore-pass-page restore-pass-page--new-pass page dark-profile"
      }
    >
      <AuthLogo />

      <section className="pass-section small-wrapper">
        <div className="pass-section__headings-wrapper auth-headings">
          <h1 className="pass-section__heading h3">{t("Сброс пароля")}</h1>
          <p className="pass-section__description">
            {t("Установить новый пароль")}
          </p>
        </div>
        <form className="form pass-section__form">
          <div className="form__main-content">
            <div className="pass-section__side">
              <div className="form-container password">
                <label htmlFor="password">{t("Пароль")}</label>

                <div className="password__container">
                  <button
                    type="button"
                    className="password__eye"
                    onClick={() => setShowPass(!showPass)}
                  >
                    <IconEyes />
                  </button>

                  <input
                    required
                    type={showPass ? "text" : "password"}
                    name="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <span
                    aria-label="valid-email"
                    className="form__error-message error-message"
                  ></span>
                </div>
              </div>

              <div className="form-container password">
                <label htmlFor="confirm-password">
                  {t("Повторите пароль")}
                </label>

                <div className="password__container">
                  <button
                    type="button"
                    className="password__eye"
                    onClick={() => setShowConfirmedPass(!showConfirmedPass)}
                  >
                    <IconEyes />
                  </button>

                  <input
                    required
                    type={showConfirmedPass ? "text" : "password"}
                    name="confirm-password"
                    id="confirm-password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                  <span
                    aria-label="valid-email"
                    className="form__error-message error-message"
                  ></span>
                </div>
              </div>
              <p className="error-message"> {t(error)}</p>
             
              <button
                type="submit"
                className="js-send-btn btn"
                onClick={handleSumbit}
              >
                {t("Применить")}
              </button>
            </div>
          </div>
        </form>
        <Popup props={modalProps}>
          <DepositSuccess
            description={t("Пароль успешно изменен!")}
            btnText={t("Вернутся назад")}
          />
        </Popup>
      </section>
    </div>
  );
}
