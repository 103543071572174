import { AuthLogo } from "./AuthLogo";
import { useTranslation } from "react-i18next";
import { AuthCodeConfirmation } from "./AuthCodeConfirmation";
export default function TwoFactorAuthPage(params) {
  const { t } = useTranslation();

  const handleComplete = (code) => {
    console.log(code);

    // code submit logic
  };
  return (
    <div className="restore-pass-page page">
      <AuthLogo />

      <section className="pass-section small-wrapper">
        <div className="pass-section__headings-wrapper auth-headings">
          <h1 className="pass-section__heading h3">
            {t("Двухфакторная аутентификация")}
          </h1>
          <p className="pass-section__description">
            {t("Google Authentication")}
          </p>
        </div>

        <form className="form pass-section__form-confirm-code">
          <div className="form__main-content">
            <div className="pass-section__side">
              <div className="form-container js-form-parent">
                <AuthCodeConfirmation length={6} onComplete={handleComplete} />
                <span aria-label="valid-email" className="form__error-message">
                  {t("Error message")}
                </span>
              </div>

              <button type="submit" className="js-send-btn btn">
                {t("Отправить")}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
