import { useRef, useEffect } from "react";
import { getUserIp } from "../../toolkitReducers/actions.slice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { ConfigureAI } from "../../Elements/ConfigureAI";
import { selectTheme } from "../../toolkitReducers/selectors";
import { UserInfo } from "./UserInfo";
import { ShareLinkBlock } from "./ShareLinkBlock";
import { AuthenticationBlock } from "./AuthenticationBlock";
import { ProfileControls } from "./ProfileControls";
export const ProfileSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const initialized = useRef(false);
  const inputRef = useRef();

  useEffect(() => {
    if (!initialized.current) {
      dispatch(getUserIp());
      initialized.current = true;
    }
  }, [dispatch]);

  return (
    <section
      className={
        theme === "light" ? "profile-section" : "profile-section dark-profile"
      }
    >
      <h1 className="profile-section__heading">{t("Профиль")}</h1>

      <div className="profile-section__wrapper rel">
        {/* <ConfigureAI /> */}
        <div className="profile-section__content-top">
          <div className="profile-section__content-top-side">
            <UserInfo />
          </div>

          <ShareLinkBlock ref={inputRef} />
        </div>

        <div className="profile-section__content-bottom">
          <ProfileControls />

          <AuthenticationBlock />
        </div>
      </div>
    </section>
  );
};
