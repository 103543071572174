import { forwardRef } from "react";
import { featuresList } from "../../helpers/lists";
import { FeatureItem } from "./FeatureItem";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export const FeaturesNew = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <section ref={ref} className="main-page-section features-new-section">
      <div className="section-inner-container">
        <ul className="features-list" >
          {featuresList.map(({ name, description }, idx) => (
            <li key={name + idx} data-animated='fade-in-and-scale'>
              <FeatureItem
                name={name}
                description={description}
                idx={idx + 1}
              />
            </li>
          ))}
        </ul>
        <div className="lower-part">
          <p data-animated='ltr-slide-in'>
            {t(
              "Потому что сфера криптовалютных бирж и биржевых пар дают огромное количество возможностей, каждый день создаются стартапы и новые крипто проекты, которые предоставляют шанс заработать привлекательные проценты."
            )}
          </p>
          <Link to="/register" className="link-button">
            {t("Зарегистрироваться прямо сейчас")}
          </Link>
        </div>
      </div>
    </section>
  );
});
