import { useTranslation } from "react-i18next";
import { forwardRef, useState, useCallback } from "react";
import { DevelopmentCard } from "./DevelopmentCard";
import { developmentList, developmentMap } from "../../helpers/lists";

export const DevelopmentMap = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [selectedCardidx, setSelectedCardIdx] = useState(0);

  const handleSelectCard = useCallback((idx) => {
    setSelectedCardIdx(idx);
  }, []);

  return (
    <section className="main-page-section development-map-section" ref={ref}>
      <div className="section-inner-container">
        <h3 className="animated-bg">{t("Карта развития")} CrypTouch</h3>
        <div className="flex-wrapper">
          <DevelopmentCard data={developmentMap[selectedCardidx]} />
          <ul className="development-list" >
            {developmentList.map(({ date, shortName }, idx) => (
              <li key={shortName} onClick={() => handleSelectCard(idx)} className={idx === selectedCardidx ? 'active' : ""}>
                <p>{t(date)}</p>
                <p>{shortName}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
});
