import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HistoryTable from "./HistoryTable";
import { Popup } from "../../Elements/Popup";
import { Deposit } from "../../Elements/Modals/Deposit";
import { Transfer } from "../../Elements/Modals/Transfer";
import DepositSuccess from "../Algoritms/DepositSuccess";
import { Withdraw } from "../../Elements/Modals/Withdraw";
import {
  useGetTransactionsHistoryQuery,
  useGetUserDataQuery,
} from "../../toolkitReducers/cryptouch";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
import { BalanceBlock } from "./BalanceBlock";
import { BalanceCards } from "../BalancePage/BalanceCards";
import { CurrencyPreview } from "../BalancePage/CurrencyPreview";
export default function BalancePage() {
  const [isDone, setDone] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef();
  const theme = useSelector(selectTheme);
  const { data: transactionsHistory } = useGetTransactionsHistoryQuery();
  const { data: userData } = useGetUserDataQuery();
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [transferResult, setTransferResult] = useState(null);
  const [withdrawResult, setWithdrawResult] = useState(null)
  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.code === "Escape") {
      setDepositModalOpen(false);
      setWithdrawModalOpen(false);
      setTransferModalOpen(false);
    }
  };
  const switchDone = (bool) => {
    setDone(bool);
  };
  const handleTransfer = (result) => {
    setTransferResult(result);
    switchDone(true);
  };
  const handleWithdraw= (result) => {
    setWithdrawResult(result);
    switchDone(true);
  };
  const closePopup = () => {
    switchDone(false);
    setDepositModalOpen(false);
    setWithdrawModalOpen(false);
    setTransferModalOpen(false);
  };

  const financialOperations = [
    setDepositModalOpen,
    setWithdrawModalOpen,
    setTransferModalOpen,
  ];
  return (
    <div
      className={
        theme === "light"
          ? "balance_page-body-wrapper balance-page"
          : "balance_page-body-wrapper balance-page dark-profile"
      }
    >
      <div className="profile-page__sections-wrapper medium-wrapper">
        <section className="profile-section">
          <div className="ballance-page rel profile-section__balance-page">
            <h2 className="balance_section-heading">{t("Главный баланс")}</h2>
            <div className="balance-section_upper-wrapper">
              <div className="profile-section__content-top-side">
                <div className="balance-section_left-side">
                  <BalanceCards
                    financialOperations={financialOperations}
                    inputRef={inputRef}
                  />
                </div>
              </div>
            </div>
            <h2 className="balance_section-heading">{t("Торговый баланс")}</h2>
            <div className="balance-section_trading-wrapper">
              <div className="balance-section_trading-group-wrapper">
                <div className="balance-section_left-side">
                  <div className="trading_section-currency-container">
                    <div className="balance-block-wrapper">
                      <div className="coin-name-wrapper">
                        <CurrencyPreview currency="btc" />
                        <p className="balance-section_currency_title">BTC</p>
                      </div>
                      <BalanceBlock
                        balanceName={"Транзитный баланс"}
                        balanceAmount={userData?.trading_crypto_balance_btc}
                        coinName={"BTC"}
                      />
                      <BalanceBlock
                        balanceName={"Base Баланс"}
                        balanceAmount={
                          userData?.trading_crypto_balance_base_btc
                        }
                        coinName={"BTC"}
                      />
                      <BalanceBlock
                        balanceName={"Pro Баланс"}
                        balanceAmount={userData?.trading_crypto_balance_pro_btc}
                        coinName={"BTC"}
                      />
                      <BalanceBlock
                        balanceName={"Combo Баланс"}
                        balanceAmount={
                          userData?.trading_crypto_balance_combo_btc
                        }
                        coinName={"BTC"}
                      />
                      {/* add balance block for a new algorithm */}
                      <BalanceBlock
                        balanceName={"Профит"}
                        balanceAmount={userData?.trading_profit_btc}
                        coinName={"BTC"}
                      />
                    </div>
                    <div className="balance-block-wrapper">
                      <div className="coin-name-wrapper">
                        <CurrencyPreview currency="eth" />
                        <p className="balance-section_currency_title">ETH</p>
                      </div>
                      <BalanceBlock
                        balanceName={"Транзитный баланс"}
                        balanceAmount={userData?.trading_crypto_balance_eth}
                        coinName={"ETH"}
                      />
                      <BalanceBlock
                        balanceName={"Base Баланс"}
                        balanceAmount={
                          userData?.trading_crypto_balance_base_eth
                        }
                        coinName={"ETH"}
                      />
                      <BalanceBlock
                        balanceName={"Pro Баланс"}
                        balanceAmount={userData?.trading_crypto_balance_pro_eth}
                        coinName={"ETH"}
                      />
                      <BalanceBlock
                        balanceName={"Combo Баланс"}
                        balanceAmount={
                          userData?.trading_crypto_balance_combo_eth
                        }
                        coinName={"ETH"}
                      />
                      {/* add balance block for a new algorithm */}
                      <BalanceBlock
                        balanceName={"Профит"}
                        balanceAmount={userData?.trading_profit_eth}
                        coinName={"ETH"}
                      />
                    </div>
                    <div className="balance-block-wrapper">
                      <div className="coin-name-wrapper">
                        <CurrencyPreview currency="usdt" />
                        <p className="balance-section_currency_title">USDT</p>
                      </div>
                      <BalanceBlock
                        balanceName={"Транзитный баланс"}
                        balanceAmount={userData?.trading_crypto_balance_usdt}
                        coinName={"USDT"}
                      />
                      <BalanceBlock
                        balanceName={"Base Баланс"}
                        balanceAmount={
                          userData?.trading_crypto_balance_base_usdt
                        }
                        coinName={"USDT"}
                      />
                      <BalanceBlock
                        balanceName={"Pro Баланс"}
                        balanceAmount={
                          userData?.trading_crypto_balance_pro_usdt
                        }
                        coinName={"USDT"}
                      />
                      <BalanceBlock
                        balanceName={"Combo Баланс"}
                        balanceAmount={
                          userData?.trading_crypto_balance_combo_usdt
                        }
                        coinName={"USDT"}
                      />
                      {/* add balance block for a new algorithm */}
                      <BalanceBlock
                        balanceName={"Профит"}
                        balanceAmount={userData?.trading_profit_usdt}
                        coinName={"USDT"}
                      />
                    </div>
                  </div>
                </div>
                <Link
                  // to="/binary/transfer"
                  className="trading_section-transfer-link"
                >
                  <button
                    className="trading__section-button"
                    onClick={() => setTransferModalOpen(true)}
                  >
                    {t("Трансфер")}
                  </button>
                </Link>
              </div>
            </div>
            <h2 className="balance_section-heading">{t("Бонусный баланс")}</h2>

            <div className="balance-section_trading-group-wrapper">
              <div className="balance-section_left-side align-left">
                <div className="balance-section_trading-wrapper bonus">
                  <div className="trading_section-currency-container">
                    <div className="balance-block-wrapper">
                      <div className="coin-name-wrapper">
                        <CurrencyPreview currency="btc" />
                        <p className="balance-section_currency_title">BTC</p>
                      </div>

                      <BalanceBlock
                        balanceName={"Баланс"}
                        balanceAmount={userData?.bonus_crypto_balance_btc}
                        coinName={"BTC"}
                      />
                    </div>
                    <div className="balance-block-wrapper">
                      <div className="coin-name-wrapper">
                        <CurrencyPreview currency="eth" />
                        <p className="balance-section_currency_title">ETH</p>
                      </div>
                      <BalanceBlock
                        balanceName={"Баланс"}
                        balanceAmount={userData?.bonus_crypto_balance_eth}
                        coinName={"ETH"}
                      />
                    </div>
                    <div className="balance-block-wrapper">
                      <div className="coin-name-wrapper">
                        <CurrencyPreview currency="usdt" />
                        <p className="balance-section_currency_title">USDT</p>
                      </div>
                      <BalanceBlock
                        balanceName={"Баланс"}
                        balanceAmount={userData?.bonus_crypto_balance_usdt}
                        coinName={"USDT"}
                      />
                    </div>

                  </div>
                  <Link
                      // to="/binary/transfer"
                      onClick={() => setTransferModalOpen(true)}
                      className="trading_section-transfer-link"
                    >
                      <button className="trading__section-button">
                        {t("Трансфер")}
                      </button>
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <Popup
            props={{
              expanded: depositModalOpen,
              onModalClose: closeModal,
            }}
            children={
              isDone ? (
                <DepositSuccess
                  title={t("Поздравляем!")}
                  description={t(
                    "Средства поступят на баланс в ближайшее время"
                  )}
                  switchDone={closePopup}
                />
              ) : (
                <Deposit switchDone={switchDone} />
              )
            }
          />
          <Popup
            props={{
              expanded: withdrawModalOpen,
              onModalClose: closeModal,
            }}
            children={
              isDone ? (
                <DepositSuccess
                title={
                  withdrawResult === "success"
                    ? t("Поздравляем!")
                    : t("Внимание")
                }
        
                  description={
                    withdrawResult === "success"
                      ? t("Спасибо, Ваша заявка на вывод принята и будет обработана в ближайшее время.")
                      : t("Вывод средств доступен только по Четвергам!")
                  }
                  switchDone={closePopup}
                />
              ) : (
                <Withdraw switchDone={handleWithdraw} />
              )
            }
          />
          <Popup
            props={{
              expanded: transferModalOpen,
              onModalClose: closeModal,
            }}
            children={
              isDone ? (
                <DepositSuccess
                  title={
                    transferResult === "success"
                      ? t("Поздравляем!")
                      : t("Внимание")
                  }
                  description={
                    transferResult === "success"
                      ? t("Трансфер успешен")
                      : t("Вывод средств доступен только по Четвергам!")
                  }
                  type={transferResult === "success" ? "success" : "warning"}
                  switchDone={closePopup}
                />
              ) : (
                <Transfer switchDone={handleTransfer} />
              )
            }
          />
        </section>
        <section
          className={
            theme === "light"
              ? "balance_page-sessions-section medium-wrapper"
              : "balance_page-sessions-section medium-wrapper dark-profile"
          }
        >
          <div className="sessions-section__header">
            <h2 className="sessions-section__header-heading">
              {t("История транзакций")}
            </h2>
          </div>
          <HistoryTable transactionsHistory={transactionsHistory} />
        </section>
      </div>
    </div>
  );
}
