import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export const HeroNew = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();

  return (
    <section className="main-page-section hero-new-section" ref={ref}>

      <div className="section-inner-container">
      <div className="cryptouch-logo-wrapper" data-animated='fade-in-and-scale'>
        <p>{t("Коснитесь будущего вместе с СrypTouch")}</p>
      </div>
      <div className="face-bg-wrapper" ></div>
        <div className="content-wrapper">
          <h1>
            {t("Мы разработали алгоритмы которые ")}
            <span>{t("будут работать на Вас.")}</span>
          </h1>

          <div className="link-wrapper">
            <Link to="/register" className="link-button">
              {t("Зарегистрироваться прямо сейчас")}
            </Link>
            <a
              className=""
              rel="noreferrer"
              target="_blank"
              href={
                i18n.resolvedLanguage === "en"
                  ? "https://www.youtube.com/watch?v=SHS4pLtPbcw"
                  : "https://www.youtube.com/watch?v=_cmWSqBhzFM"
              }
            >
              {t("Видео регистрации")}
            </a>
          </div>
        </div>
      </div>

    </section>
  );
});
