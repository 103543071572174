import { useRef } from "react";
import { ReactComponent as X } from "../../assets/icons/x-gradient.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../toolkitReducers/selectors";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { ReactComponent as CopyEmail } from "../../assets/icons/refferal-form/copy-email.svg";
import { ReactComponent as Instagram } from "../../assets/icons/refferal-form/instagram.svg";
import { ReactComponent as Telegram } from "../../assets/icons/refferal-form/telegram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/refferal-form/facebook.svg";
const Promo = ({ lvl }) => {
  const { data } = useGetUserDataQuery();
  const language = useSelector(selectLanguage);
  let profit;

  if (lvl < 4) {
    profit = 5;
  } else if (lvl > 4) {
    profit = 9;
  } else {
    profit = 7;
  }

  const { t } = useTranslation();
  const inputRef = useRef(null);
  return (
    <div className="promo-wrapper">
      <div className="referals-form-wrapper">
        <form className="rel">
          <label
            className="copy-email-label"
            aria-roledescription="button"
            htmlFor="register-link"
            onClick={() =>
              navigator.clipboard.writeText(inputRef.current.value)
            }
          >
            <CopyEmail />
          </label>
          <input
            readOnly
            ref={inputRef}
            className="copy-email"
            type="text"
            name="register-link"
            id="register-link"
            value={`https://cryptouch.ai/register?ref_id=${
              data ? data.id.toString().padStart(5, "1000") : "0"
            }`}
          />
        </form>
        <div className="referals-section-social-links">
          <p>{t("Поделится")}:</p>

          <ul>
            <a
              target="blank"
              href="https://www.instagram.com/cryptouchai/profilecard/?igsh=MXhkNjhjcnY2dW8xYw=="
            >
              <li>
                <Instagram />
              </li>
            </a>

            <a
              target="blank"
              href={`https://www.facebook.com/sharer.php?u=https://cryptouch.ai/register?ref_id=${`${
                Number(localStorage.getItem("id"))
                  ? localStorage.getItem("id").padStart(5, "1000")
                  : "0"
              }`}?hashtag=#cryptouch`}
            >
              <li>
                <Facebook />
              </li>
            </a>

            <a
              target="blank"
              href={`https://t.me/share/url?url=https://cryptouch.ai/register?ref_id=${`${
                Number(localStorage.getItem("id"))
                  ? localStorage.getItem("id").padStart(5, "1000")
                  : "0"
              }`}&text=Join me on Cryptouch.ai`}
            >
              <li>
                <Telegram />
              </li>
            </a>

            <a
              className="X-share-button"
              target="blank"
              href={`https://twitter.com/intent/tweet?text=Join me on Cryptouch.ai. My link is &url=${`https://cryptouch.ai/register?ref_id=${
                Number(localStorage.getItem("id"))
                  ? localStorage.getItem("id").padStart(5, "1000")
                  : "0"
              }`}`}
            >
              <li>
                <X />
              </li>
            </a>
          </ul>
        </div>
        <div className="referals-section-fundraisers-wrapper">
          <div>
            <p className="fundraisers-title">{t("Уровень реферала") + ":"}</p>
            <p>
              <span>{`${profit}% `}</span>
              {t("Ваш доход")}
            </p>
          </div>
          <div className="star-level-wrapper">
            <span className="icon-holder rel">
              <Star />
              <span className="current-level">{lvl}</span>
            </span>
            <p>{t("Уровень") + " " + lvl}</p>
          </div>
        </div>
        <div className="promo-buttons">
        <a
          className="promo-btn"
          href={
            language === "en"
              ? "https://cryptouch.ai/staticDirectory/pages/presentation-refs.pdf"
              : "https://cryptouch.ai/staticDirectory/pages/presentation-refs-ru.pdf"
          }
        >
          {t("Презентация")}
        </a>
        <a
          className="promo-btn"
          href={
            language === "en"
              ? "https://www.youtube.com/watch?v=_0sDbxULa34"
              : "https://www.youtube.com/watch?v=2-fKxaZQelw"
          }
        >
          {t("Смотреть видео")}
        </a>
      </div>
      </div>

    </div>
  );
};

export { Promo };
