import { AuthLogo } from "./AuthLogo";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthError, selectTheme } from "../../toolkitReducers/selectors";
import { useState } from "react";
import { ReactComponent as IconEyes } from "../../assets/icons/PassIcon.svg";
import { ModalWindow } from "../../Elements/BinaryBots/ModalWindow";
import { Popup } from "../../Elements/BinaryBots/Popup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setNewPassword } from "../../toolkitReducers";

export default function SetNewPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmedPass, setShowConfirmedPass] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const responseError = useSelector(selectAuthError);
  const dispatch = useDispatch();

  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.target.keyCode === "ESC") {
      setIsModalOpen(false);
    }
  };
  const closePopup = () => {
    setIsModalOpen(false);
    navigate("/login");
  };
  async function handleSumbit(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Пароли не совпадают");
      return;
    }
    if (password.length < 6) {
      setError("Пароль должен состоять минимум из 6 символов");
      return;
    }
    setError(null);
    try {
      await dispatch(
        setNewPassword({
          password,
          confirmPassword,
          uid: searchParams.get("uid"),
          token: searchParams.get("token"),
        })
      ).unwrap();
      setIsModalOpen(true);
    } catch (err) {
      setError(err?.message);
    }
  }
  return (
    <div
      className={
        theme === "light"
          ? "restore-pass-page page"
          : "restore-pass-page page dark-profile"
      }
    >
      <AuthLogo />
      <section className="pass-section small-wrapper">
        <div className="pass-section__headings-wrapper auth-headings">
          <h1 className="pass-section__heading h3">
            {" "}
            {t("Установить новый пароль")}
          </h1>
          <p className="pass-section__description">
            {t("Введите новый пароль")}
          </p>
        </div>

        <form className="form pass-section__form">
          <div className="form__main-content">
            <div className="pass-section__side">
              <div className="form-container password">
                <label htmlFor="password">{t("Пароль")}</label>

                <div className="password__container">
                  <button
                    type="button"
                    className="password__eye"
                    onClick={() => setShowPass(!showPass)}
                  >
                    <IconEyes />
                  </button>

                  <input
                    required
                    type={showPass ? "text" : "password"}
                    name="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <span
                    aria-label="valid-email"
                    className="form__error-message error-message"
                  ></span>
                </div>
              </div>

              <div className="form-container password">
                <label htmlFor="confirm-password">
                  {t("Повторите пароль")}
                </label>

                <div className="password__container">
                  <button
                    type="button"
                    className="password__eye"
                    onClick={() => setShowConfirmedPass(!showConfirmedPass)}
                  >
                    <IconEyes />
                  </button>

                  <input
                    required
                    type={showConfirmedPass ? "text" : "password"}
                    name="confirm-password"
                    id="confirm-password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                  {responseError && (
                    <span
                      aria-label="valid-email"
                      className="form__error-message error-message"
                    >
                      {t(responseError)}
                    </span>
                  )}
                </div>
              </div>
              <p className="error-message"> {t(error)}</p>
              <button
                type="submit"
                className="js-send-btn btn"
                onClick={handleSumbit}
              >
                {t("Применить")}
              </button>
            </div>
          </div>
        </form>
        <ModalWindow onModalClose={closeModal} expanded={isModalOpen}>
          <Popup
            description={t("Пароль успешно изменен!")}
            switchDone={closePopup}
          />
        </ModalWindow>
      </section>
    </div>
  );
}
