import { useTranslation } from "react-i18next";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
import { useState, Fragment } from "react";
import { ReactComponent as ArrowExpandTree } from "../../assets/icons/refferals-tree-table/arrow-expand-tree.svg";
export const RefferalsSubtree = ({ subRefferalsArray, parentIdx, level }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const toggleExpandRow = (idx) => {
    setExpandedRows(
      (prevExpandedRows) =>
        prevExpandedRows.includes(idx)
          ? prevExpandedRows.filter((rowIdx) => rowIdx !== idx) // Collapse row
          : [...prevExpandedRows, idx] // Expand row
    );
  };

  const { t } = useTranslation();

  return (
    subRefferalsArray.length > 0 && (
      <table className="refferals-tree-table-inner">
        <tbody>
          {[...subRefferalsArray]
            .sort(
              (a, b) =>
                new Date(b.registration_date) - new Date(a.registration_date)
            )
            .map((ref, idx) => (
              <Fragment key={`${parentIdx}-${idx}-subtree-row`}>
                <div className="user-network-filter-wrapper">
                  <button>{t("Пользователь")}</button>
                  {/* <button>{t("Сеть")}</button> */}
                </div>
                <tr>
                  <td className="cell-with-opener">
                    {ref.referrals.length > 0 && (
                      <button onClick={() => toggleExpandRow(idx)}>
                        <ArrowExpandTree
                          className={
                            expandedRows.includes(idx)
                              ? "arrow-expand-tree expanded"
                              : "arrow-expand-tree"
                          }
                        />
                      </button>
                    )}

                    {level}
                  </td>
                  <td className="email-cell">{ref.email}</td>
                  <td>
                    {transformDateTimeForSessionsTable(ref.registration_date)}
                  </td>
                  <td>{ref.referrals_count}</td>
                  <td>{ref.current_referral_level}</td>
                  <td>{ref.deposit_sum.toFixed(2)} USDT</td>
                  <td>{ref.total_referrals_deposit_sum.toFixed(2)} USDT</td>
                </tr>

                {expandedRows.includes(idx) && ref.referrals && (
                  <RefferalsSubtree
                    subRefferalsArray={ref.referrals}
                    parentIdx={`${parentIdx}-${idx}`}
                    level={level + 1}
                  />
                )}
              </Fragment>
            ))}
        </tbody>
      </table>
    )
  );
};
