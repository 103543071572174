import { useTranslation } from "react-i18next";
import { ReactComponent as SuccessDarkMode } from '../../assets/icons/Popups/success-dark-mode.svg';
import { ReactComponent as WarningDarkMode } from "../../assets/icons/Popups/warning-dark-mode.svg";
import { ReactComponent as Success } from "../../assets/icons/Popups/success.svg";
import { ReactComponent as Warning } from "../../assets/icons/Popups/warning.svg";
import { ReactComponent as Failure } from "../../assets/icons/Popups/failure.svg";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
import { NextButton } from "./NextButton";
export function Popup({
  title = "Поздравляем!",
  description = "Ваша заявка в обработке",
  switchDone,
  type = "success",
  btnText = "OK",
}) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  return (
    <div className="popup-wrapper">
      <div className="popup-icon-thumb">
      {type === "success" && theme === "light" && <Success />}
        {type === "success" && theme !== "light" && <SuccessDarkMode />}
        {type === "warning" && theme === "light" && <Warning />}
        {type === "warning" && theme !== "light" && <WarningDarkMode />}
        {type === "failure" && <Failure />}
      </div>

      <h2 className="popup-status-title">{t(title)}</h2>

      <p className="popup-description">{t(description)}</p>
      <NextButton onClick={switchDone} text={btnText} />
    </div>
  );
}
