import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SocialLinksList } from "./SocalLinksList";
import { ReactComponent as CopyText } from "../../assets/icons/profile-section/copy.svg";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";

export const ShareLinkBlock = forwardRef((props, ref) => {
    const {t} = useTranslation()
      const { data } = useGetUserDataQuery();
    return(
        <div className="profile-section__content-top-side">
    <p className="profile-section__heading">
      {t("Ссылка для регистрации")}
    </p>

    <div className="profile-section__input">
      <label
        className="copy-email-label"
        aria-roledescription="button"
        htmlFor="register-link"
        onClick={() =>
          navigator.clipboard.writeText(ref.current.value)
        }
      >
        <CopyText />
      </label>
      <input
        className="copy-email"
        type="text"
        name="register-link"
        id="register-link"
        value={`https://cryptouch.ai/register?ref_id=${
          data ? data.id.toString().padStart(5, "1000") : "0"
        }`}
        readOnly
        ref={ref}
      />
    </div>

    <SocialLinksList />
  </div>
    )
})