import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigureAI } from "../../Elements/ConfigureAI";
import { selectTheme } from "../../toolkitReducers/selectors";
import { useSelector } from "react-redux";
export default function KYCPage(params) {
  const location = useLocation();
  const regex = /\/(\w+)$/;
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const tabIndex = regex.exec(location.pathname)[0];
  let activeTab = tabIndex === "/step1" ? true : false;

  return (
    <div
      className={
        theme === "light" ? "KYS-page page" : "KYS-page page dark-profile"
      }
    >
      <div className="KYS-page__body-wrapper">
        <div className="KYS-page__sections-wrapper">
          <section className="KYS-section">
            <h1 className="KYS-section__heading h3">KYC</h1>

            <div className="KYS-section__wrapper rel">
              <ConfigureAI />

              <form
                className="form KYS__form"
                action="/action_page.php"
                method="post"
              >
                <div className="tabs-component">
                  <div
                    className="tabs__navigation hide-scrollbar"
                    role="tablist"
                    aria-labelledby="tablist"
                  >
                    <div
                      className="tabs__header-tab btn btn--primary"
                      role="tab"
                      type="button"
                      tabIndex="0"
                      id="tab-1"
                      aria-controls="tabpanel-1"
                      aria-selected={activeTab}
                    >
                      <span className="world_1">{t("Шаг")}</span>
                      <span className="world_2">1</span>
                    </div>

                    <div
                      className="tabs__header-tab btn btn--primary"
                      role="tab"
                      type="button"
                      tabIndex="-1"
                      id="tab-2"
                      aria-controls="tabpanel-2"
                      aria-selected={!activeTab}
                    >
                      <span className="world_1">{t("Шаг")}</span>
                      <span className="world_2">2</span>
                    </div>
                  </div>

                  {/*
                                        <Routes>
                                            <Route path="step1" element={<KYCStep1 />} />
                                            <Route path="profile/kyc/step2" element={<KYCStep2 />} />
                                        </Routes> */}
                </div>
                <Outlet />
                <span className="form__error-message hide">
                  {t("Данные заполнены не корректно или не полностью")}
                </span>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
