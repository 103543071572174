import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { trunc } from "../../helpers/trunc";
import { balanceCardsList } from "../../helpers/lists";
import { BalanceCard } from "./BalanceCard";
import { ReferalForm } from "./ReferalForm";

export const BalanceCards = ({ financialOperations, inputRef }) => {
  const { data: userData } = useGetUserDataQuery();

  return (
    <ul className="balance-cards">
      {balanceCardsList.map(({ currency, financialOperationType }, i) => (
        <li key={`balance-block-${currency}`}>
          <BalanceCard
            coinName={currency}
            currentBalance={trunc(
              userData?.[`main_crypto_balance_${currency}`]
            )}
            financialOperationType={financialOperationType}
            handleClick={financialOperations[i]}
          />
        </li>
      ))}
      <li key="balance-block-referal-form">
      <ReferalForm id={userData?.id} ref={inputRef}/>
      </li>
    </ul>
  );
};
