import { useTranslation } from "react-i18next";
import { ReactComponent as PurplePoint } from "../../assets/icons/promo-section/purple-circle.svg";

export const AlgorithmPromoCard = ({ type, features, launchSum }) => {
  const { t } = useTranslation();
  return (
    <div className="card-thumb">
      <h4>{t(type)}:</h4>
      <ul data-animated='fade-in-and-scale'>
        {features.map((feature, idx) => (
          <li key={type + "feature" + idx}>
            <PurplePoint />
            <p>{t(feature)}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
