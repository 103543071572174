import { Popup } from "./Popup";
import { useState } from "react";
import { ReactComponent as Close } from "../../assets/icons/advantages-section/close.svg";
import { useTranslation } from "react-i18next";

export const NewsItem = ({ image, title, description, link }) => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const {t} = useTranslation()
  return (
    <>
      <div className="thumb" onClick={openModal}>
        <img src={image} alt={title} />
      </div>
      <h4>{title}</h4>
      <p>{description}</p>
      <Popup
        props={{
          expanded: open,
          onModalClose: closeModal,
        }}
      >
        <Close className="close-icon" />
        <img src={image} alt={title} />
        <h4>{title}</h4>
        <p>{description}</p>
        <a className="link" href={link} target="_blank" rel="noopener noreferrer">{t("Читать полностью")}</a>
      </Popup>
    </>
  );
};
