import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "dark",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme(state) {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
  },
});
// Middleware to update body background
export const themeMiddleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  if (action.type === "theme/toggleTheme") {
    const { theme } = storeAPI.getState();
    document.body.style.background = theme?.theme === "dark" ? "#0B091A" : "#fff";
  }

  return result;
};

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
