import { useTranslation } from "react-i18next";
export const FeatureItem = ({ idx, name, description }) => {
  const { t } = useTranslation();
  return (
    <div className="feature-wrapper">
      <div className="bg-thumb"></div>
      <p className="number">{idx}</p>
      <div className="content-block">
        <span>{t(name)}:</span>
        <p>{t(description)}</p>
      </div>
    </div>
  );
};
