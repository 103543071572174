import { AuthLogo } from "./AuthLogo";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthError, selectTheme } from "../../toolkitReducers/selectors";
import { useState } from "react";
import { restorePassword } from "../../toolkitReducers/auth.slice";
import { ModalWindow } from "../../Elements/BinaryBots/ModalWindow";
import { Popup } from "../../Elements/BinaryBots/Popup";
import { useNavigate } from "react-router-dom";

export default function RestorePass() {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const error = useSelector(selectAuthError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.target.keyCode === "ESC") {
      setIsModalOpen(false);
    }
  };
  const closePopup = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      navigate("/");
    }, 3000);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await dispatch(restorePassword({ email })).unwrap();
      setMessage(t("Password reset link sent to your email"));
      setIsModalOpen(true);
    } catch (error) {
      setMessage(error.message || t("An error occurred"));
    }
  };
  return (
    <div
      className={
        theme === "light"
          ? "restore-pass-page page"
          : "restore-pass-page page dark-profile"
      }
    >
      <AuthLogo />
      <section className="pass-section small-wrapper">
        <div className="pass-section__headings-wrapper auth-headings">
          <h1 className="pass-section__heading h3">{t("Сброс пароля")}</h1>
          <p className="pass-section__description">
            {t("Введите имейл адрес для сброса пароля")}
          </p>
        </div>

        <form className="form pass-section__form" onSubmit={handleSubmit}>
          <div className="form__main-content">
            <div className="pass-section__side">
              <div className="form-container js-form-parent">
                <label htmlFor="email">{t("Email адрес")}</label>
                <input
                  required
                  type="email"
                  placeholder="Email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <p className="error-message">{error && t(message)}</p>
              </div>

              <button type="submit" className="js-send-btn btn">
                {t("Отправить")}
              </button>
            </div>
          </div>
        </form>
        <ModalWindow onModalClose={closeModal} expanded={isModalOpen}>
          <Popup
            title={t("Заявка на изменение пароля отправлена")}
            description={message}
            switchDone={closePopup}
          />
        </ModalWindow>
      </section>
    </div>
  );
}
