import { useSelector } from "react-redux";

import { ReactComponent as Instagram } from "../../assets/icons/profile-section/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/profile-section/facebook.svg";
import { ReactComponent as Telegram } from "../../assets/icons/profile-section/telegram.svg";
import { ReactComponent as X } from "../../assets/icons/x-gradient.svg";
import { ReactComponent as XDark } from "../../assets/icons/x-dark.svg";
import { selectTheme } from "../../toolkitReducers/selectors";
import { useTranslation } from "react-i18next";
export const SocialLinksList = () => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  return (
    <div className="profile-section__social-links-wrapper">
      <p className="profile-section__social-links-title">{t("Поделится")}:</p>

      <ul className="profile-section__social-links-list">
        <a
          target="blank"
          href="https://www.instagram.com/cryptouchai/profilecard/?igsh=MXhkNjhjcnY2dW8xYw=="
        >
          <li className="profile-section__social-links-list-item link">
            <Instagram />
          </li>
        </a>

        <a
          target="blank"
          href={`https://www.facebook.com/sharer.php?u=https://cryptouch.ai/register?ref_id=${`${
            Number(localStorage.getItem("id"))
              ? localStorage.getItem("id").padStart(5, "1000")
              : "0"
          }`}?hashtag=#cryptouch`}
        >
          <li className="profile-section__social-links-list-item link">
            <Facebook />
          </li>
        </a>

        <a
          target="blank"
          href={`https://t.me/share/url?url=https://cryptouch.ai/register?ref_id=${`${
            Number(localStorage.getItem("id"))
              ? localStorage.getItem("id").padStart(5, "1000")
              : "0"
          }`}&text=Join me on Cryptouch.ai`}
        >
          <li className="profile-section__social-links-list-item link">
            <Telegram />
          </li>
        </a>

        <a
          target="blank"
          href={`https://twitter.com/intent/tweet?text=Join me on Cryptouch.ai. My link is &url=${`https://cryptouch.ai/register?ref_id=${
            Number(localStorage.getItem("id"))
              ? localStorage.getItem("id").padStart(5, "1000")
              : "0"
          }`}`}
        >
          <li className="profile-section__social-links-list-item link">
            {theme === "light" ? <X /> : <XDark />}
          </li>
        </a>
      </ul>
    </div>
  );
};
