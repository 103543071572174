import { Link } from "react-router-dom";
import { ReactComponent as SmallLogo } from "../../assets/icons/logo-header-main.svg";
import { socialLinks, lowerPartLinks } from "./links";
import { useTranslation } from "react-i18next";
import { FooterLinks } from "./FooterLinks";
import { LowerPartLinks } from "./LowerPartLinks";
const Footer = ({ onProfilePage }) => {
  const { t } = useTranslation();
  return (
    <footer
      className={`main-page-footer ${onProfilePage ? "on-profile-page" : ""}`}
    >
      <div className="footer-upper-wrapper">
        <div className="main-footer-info-logo">
          <Link to="/profile">
            <SmallLogo className="img-abs" />
          </Link>
        </div>
        <FooterLinks />
        <ul className="main-footer-social-links">
          {socialLinks.map(({ name, element, link }, index) => (
            <li key={name + index}>
              <a href={link} rel="noreferrer" target="_blank">
                {element}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <ul className="footer-contacts">
        <li key="link-tel">
          <a href="tel:+447418355962">+447418355962</a>
        </li>
        <li key="link-eamil">
          <a href="mailto:cryptouch.help@gmail.com">cryptouch.help@gmail.com</a>
        </li>
      </ul>
      <div className="footer-lower-wrapper">
        <div>
          <p>20 Wenlock Road</p>
          <p>London, England, N1 7GU</p>
        </div>
        <LowerPartLinks />
        <p>{t("Copyright © 2024. All rights reserved.")}</p>
      </div>
    </footer>
  );
};

export { Footer };
