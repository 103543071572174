import { ReactComponent as TwoFAIcon } from "../../assets/icons/profile-section/2fa.svg";
import { ReactComponent as Circle } from "../../assets/icons/profile-section/circle-icon.svg";
import { ReactComponent as KYCIcon } from "../../assets/icons/profile-section/kyc.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Popup } from "../../Elements/Popup";
import DepositSuccess from "../Algoritms/DepositSuccess";
import {
  useGetUserDataQuery,
  useToggle2FAMutation,
} from "../../toolkitReducers/cryptouch";
export const AuthenticationBlock = () => {
  const { data, isLoading, isFetching } = useGetUserDataQuery();
  const [toggle2FA, {error}] = useToggle2FAMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const { t } = useTranslation();

  const handle2FAToggle = async () => {
    try {
      const result = await toggle2FA(data.is_2fa_enabled).unwrap();
      setResponseMsg(result.message);
    } catch (error) {
      setResponseMsg(error.data.message);
    }

    setModalOpen(true);
  };
  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.code === "Escape") {
      setModalOpen(false);
    }
  };

  const closePopup = () => {
    setModalOpen(false);
  };
  return (
    <div className="profile-section__button-wrapper">
      <Link
        to="/profile/kyc/step1"
        className="profile-section__big-button link rel"
      >
        <div className="profile-section__big-button-left">
          <span className="profile-section__big-button-title">KYC</span>
          <span className="profile-section__big-button-state">
            {t("НАЧАТЬ")}
          </span>
        </div>

        <div className="profile-section__big-button-right">
          <Circle />
          <KYCIcon />
        </div>
      </Link>

      <button
        onClick={handle2FAToggle}
        className="profile-section__big-button link rel"
        disabled={isFetching || isLoading}
      >
        <div className="profile-section__big-button-left">
          <span className="profile-section__big-button-title">2FA</span>
          <span className="profile-section__big-button-state">
            {data?.is_2fa_enabled ? t("ВКЛ") : t("ВЫКЛ")}
          </span>
        </div>

        <div className="profile-section__big-button-right">
          <Circle />
          <TwoFAIcon />
        </div>
      </button>
      <Popup
        props={{
          expanded: modalOpen,
          onModalClose: closeModal,
        }}
        children={
          <DepositSuccess
            title={error ? t('Ошибка') : t("Поздравляем!")}
            description={t(responseMsg)}
            switchDone={closePopup}
            type={error ? 'failure' : 'success'}
          />
        }
      />
    </div>
  );
};
