import { CurrencyPreview } from "../BalancePage/CurrencyPreview";
import { useTranslation } from "react-i18next";
export const BalanceList = ({ balance, type='bonus' }) => {
    const { t } = useTranslation();
  return (
    <ul className="balance-list">
      <li>
        <div className="coin-name-wrapper">
          <CurrencyPreview currency="btc" />
          <p>{t("Баланс")} BTC</p>
        </div>
        <span>{parseFloat(type === 'bonus' ? balance?.btc : balance?.main_crypto_balance_btc) + " BTC"}</span>
      </li>
      <li>
        <div className="coin-name-wrapper">
          <CurrencyPreview currency="eth" />
          <p>{t("Баланс")} ETH</p>
        </div>
        <span>{parseFloat(type === 'bonus' ? balance?.eth : balance?.main_crypto_balance_btc) + " ETH"}</span>
      </li>
      <li>
        <div className="coin-name-wrapper">
          <CurrencyPreview currency="usdt" />
          <p>{t("Баланс")} USDT</p>
        </div>
        <span>{parseFloat(type === 'bonus' ? balance?.usdt : balance?.main_crypto_balance_btc).toFixed(2) + " USDT"}</span>
      </li>
    </ul>
  );
};
