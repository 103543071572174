import { useTranslation } from "react-i18next";
import { Popup } from "./Popup";
import { useState } from "react";
import { ReactComponent as Close } from "../../assets/icons/advantages-section/close.svg";
export const Advantage = ({
  img,
  alt = "crypto",
  text,
  id,
  description,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  return (
    <div className='thumb' data-animated='fade-in-and-scale'>
      <img src={img} className="" alt={alt} />
      <p>{t(text)}</p>
      <button id={id} onClick={openModal}>
        {t("Подробнее")}
      </button>
      <Popup
        props={{
          expanded: open,
          onModalClose: closeModal,
        }}
        children={
          <>
            <Close className="close-icon" />
            <h4>{t(text)}</h4>
            <p>{t(description)}</p>
          </>
        }
      />
    </div>
  );
};
