import { useTranslation } from "react-i18next";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
import { useState, Fragment } from "react";
import { ReactComponent as ArrowExpandTree } from "../../assets/icons/refferals-tree-table/arrow-expand-tree.svg";
import { TableSmallSubtree } from "./TableSmallSubtree";
const TableSmall = ({ refferals }) => {
  const { t } = useTranslation();
  // Toggle row expansion
  const [expandedRows, setExpandedRows] = useState([]);
  const toggleExpandRow = (idx) => {
    setExpandedRows(
      (prevExpandedRows) =>
        prevExpandedRows.includes(idx)
          ? prevExpandedRows.filter((rowIdx) => rowIdx !== idx) // Collapse row
          : [...prevExpandedRows, idx] // Expand row
    );
  };
  return (
    <div className="referals-page__table-wrapper">
      <table>
        <thead>
          <tr className="referals-page__thead rel">
            <th>{t("Дата деп")}</th>
            <th>{t("Почта реферала")}</th>
            <th>{t("Сумма деп")} USDT</th>
            <th>{t("Сумма деп")} BTC</th>
            <th>{t("Сумма деп")} ETH</th>
            <th>{t("Бонус")} USDT</th>
            <th>{t("Бонус")} BTC</th>
            <th>{t("Бонус")} ETH</th>
          </tr>
        </thead>
        <tbody>
          {refferals?.all_referrals?.length > 0 &&
            refferals.all_referrals.map((ref, idx) => (
              <Fragment key={`${idx}-small-table-row`}>
                <tr className="referals-page__table-body-row">
                  <td className="cell-with-opener">
                    {ref.referrals.length > 0 && (
                      <button onClick={() => toggleExpandRow(idx)}>
                        <ArrowExpandTree
                          className={
                            expandedRows.includes(idx)
                              ? "arrow-expand-tree expanded"
                              : "arrow-expand-tree"
                          }
                        />
                      </button>
                    )}
                    {transformDateTimeForSessionsTable(ref.last_deposit_date) ||
                      "-"}
                  </td>
                  <td className="email-cell">{ref.email}</td>
                  <td>
                    {ref.last_deposit_sum_usdt
                      ? parseFloat(ref.last_deposit_sum_usdt).toFixed(2)
                      : 0}
                  </td>
                  <td>{ref.last_deposit_sum_btc || 0}</td>
                  <td>{ref.last_deposit_sum_eth || 0}</td>
                  <td>
                    {parseFloat(ref.bonus_crypto_balance.usdt).toFixed(2) || 0}
                  </td>
                  <td>{ref.bonus_crypto_balance.btc || 0}</td>
                  <td>{ref.bonus_crypto_balance.eth || 0}</td>
                </tr>
                {expandedRows.includes(idx) && (
                  <TableSmallSubtree
                    subRefferalsArray={ref.referrals}
                    parentIdx={`parent-${idx}-small`}
                    level={2}
                  />
                )}
              </Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export { TableSmall };
