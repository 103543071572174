import { ProfileSection } from "./ProfileSection";
import { SessionsTable } from "../Binary/SessionsTable";
import { useSetUserDataMutation } from "../../toolkitReducers/cryptouch";
import { useFetchUserIpQuery } from "../../toolkitReducers/ipDataApi";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import BrowserDetector from "browser-dtector";
import { selectTheme } from "../../toolkitReducers/selectors";
export default function ProfilePage() {
  const theme = useSelector(selectTheme);
  const browser = new BrowserDetector(window.navigator.userAgent);
  let { name, platform } = browser.parseUserAgent();
  const init = useRef(false);
  const { data: ip } = useFetchUserIpQuery();
  const [setUserData] = useSetUserDataMutation();

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      return;
    }
    setUserData({
      ip: ip?.ip,

      browser: name,
      os: platform,
    });
  }, [ip?.ip, name, platform, setUserData]);
  return (
    <div
      className={
        theme === "light"
          ? "profile-page__body-wrapper"
          : "profile-page__body-wrapper dark-profile"
      }
    >
      <div className="profile-page__sections-wrapper medium-wrapper">
        <ProfileSection />
        <SessionsTable />
      </div>
    </div>
  );
}
