import { useTranslation } from "react-i18next";
import { DevelopmentSublist } from "./DevelopmmentSublist";
export const DevelopmentCard = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="development-card">
      <h4>{data.period}</h4>
      <ul className="goals-list" data-animated='ltr-slide-in'>
        {data.goals.map(({ goalTitle, goalDetails }, idx) => (
          <li key={goalTitle+idx}>
            <p className="goal-title">{t(goalTitle)}</p>
            <DevelopmentSublist items={goalDetails} />
          </li>
        ))}
      </ul>
    </div>
  );
};
