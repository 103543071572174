import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { forwardRef } from "react";
import {ReactComponent as PurpleCircle} from '../../assets/icons/promo-section/purple-circle.svg'
import { CrossStripesBranding } from "./CrossStripesBranding";
export const PromoNew = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  return (
    <section className="main-page-section promo-new-section" ref={ref}>
      <div className="section-inner-container">
      <div className="face-bg-wrapper" data-animated='fade-in-and-scale'></div>
        <div className="content-wrapper">
        <h2 data-animated='ltr-slide-in'>
          {t("CrypTouch: Революция в разработке на базе ")}
          <span>{t("TensorFlow 2.0")}</span>
        </h2>
        <p className="description" data-animated='ltr-slide-in'>
          {t(
            "Станьте частью нашей партнерской программы совершенно бесплатно и начните зарабатывать уже сегодня."
          )}
        </p>
        <div className="sub-description-block" data-animated='ltr-slide-in'>
            <PurpleCircle />
            <p >{t("Получайте ваш заработок еженедельно.")}</p>
        </div>

        <div className="link-wrapper">
          <Link to="/register" className="link-button">
            {t("Зарегистрироваться прямо сейчас")}
          </Link>
          <a
            className=""
            rel="noreferrer"
            target="_blank"
            href={
              i18n.resolvedLanguage === "en"
                ? "https://www.youtube.com/watch?v=SHS4pLtPbcw"
                : "https://www.youtube.com/watch?v=_cmWSqBhzFM"
            }
          >
            {t("Видео регистрации")}
          </a>
        </div>
        </div>

      </div>
      <CrossStripesBranding/>
    </section>
  );
});
